import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Blobs from '../img/blobs/index.js';

export default function ImageWithText(props) {
  const { height = 400, width = 400, img, title, index, subheading, textSize } = props;

  function getRandomBlobImage(Blobs) {
    const values = Object.values(Blobs);
    return values[index % values.length];
  }
  function getRandomRotation() {
    const rotations = [0, 45, 90, 135, 180, 225, 270];
    return rotations[Math.floor(Math.random() * rotations.length)];
  }
  function getRandomFlip() {
    const flipVal = [-1, 1];
    return flipVal[Math.floor(Math.random() * flipVal.length)];
  }
  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: 'grid',
          alignItems: 'center',
        }}
      >
        <svg
          // objectposition={'50% 50%'}
          style={{
            height: height,
            width: width,
            gridArea: '1/1 ',
            transform: `rotate(${getRandomRotation()}deg)`,
            transform: `scaleX(${getRandomFlip()})`,
            transform: `scaleY(${getRandomFlip()})`,
            filter: 'contrast(0.9)'
          }}>
          <defs>
            <filter id="noiseDisp" colorInterpolationFilters="sRGB">
              <feTurbulence
                baseFrequency={0.01}
                numOctaves="3"
                seed={200}
                type="fractalNoise"
                result="turbulence"
              />
              <feColorMatrix in="turbulence" type="hueRotate" values="0" result="turbulenceAnimated">
                <animate attributeName="values" from="0" to="360" dur="7s" repeatCount="indefinite" />
              </feColorMatrix>
              <feDisplacementMap
                type="displaced"
                in="SourceGraphic"
                in2="turbulenceAnimated"
                xChannelSelector="R"
                yChannelSelector="G"
                scale={18}
                result="displaced"
              />
              <feGaussianBlur in="displaced" stdDeviation="6" result="blurred" />
              <feMerge>
                <feMergeNode in="blurred" />
                <feMergeNode in="displaced" />
              </feMerge>
            </filter>
          </defs>

          <image
            className="blob-image"
            xlinkHref={getRandomBlobImage(Blobs)}
            // objectFit={"cover"}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            // objectposition={'50% 50%'}
            style={{
              // You can set a maximum height for the image, if you wish.
              height: height,
              width: width,
            }}
          />
        </svg>
        {(title || subheading) && (
          <div
            className="hoverOrange"
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: '1/1',
              position: 'relative',
              // This centers the other elements inside the hero component
              placeItems: 'center',
              display: 'grid',
              width: width,
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <p
                className="has-text-weight-bold blob-text has-text-centered"
                style={{
                  fontSize: textSize,
                }}
              >
                {title}
              </p>
            )}
            {subheading && (
              <p
                className="has-text-centered"
                style={{
                  fontSize: textSize,
                  color: 'white',
                }}
              >
                {subheading}
              </p>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

ImageWithText.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  index: PropTypes.number,
  height: PropTypes.number,
  subheading: PropTypes.string,
  textSize: PropTypes.number,
};
