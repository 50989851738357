import React from "react";
import Layout from "../../components/Layout";
import BlobImageComponent from '../../components/ImageWithText';

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <div className="columns is-mobile is-centered">
            <div className="column">
              <div className="thanksPageImage has-text-centered">
                <BlobImageComponent
                  index={0}
                  width={400}
                  height={400}
                  textSize={34}
                />
                <div className="thanksPageText is-overlay">
                  <h2>Your submission was successful!</h2>
                  <p>We are going to look through your application soon and get back to you if we have any questions.<br></br>
                    If you have questions, don’t hesitate to ask us at <span
                      className="has-text-light pointer is-family-primary is-size-5"
                      onClick={(e) => {
                        window.location.href = 'mailto:contact@ronginshagor.com ';
                        e.preventDefault();
                      }}
                    >
                      contact@ronginshagor.com
                    </span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
