import React, { useState } from 'react';
// import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import './../styles/all.sass';
import HelmetComponent from './HelmetComponent';

const TemplateWrapper = ({ children }) => {
  const [navIsActive, setNavIsActive] = useState(false);

  return (
    <div className="outerContainer">
      <HelmetComponent />
      <Navbar navIsActive={navIsActive} setNavIsActive={setNavIsActive} />
      <div>{children}</div>
      {/* <Footer /> */}
    </div>
  );
};

export default TemplateWrapper;
