import blob1 from './blob-01.svg'
import blob2 from './blob-02.svg'
import blob3 from './blob-03.svg'
import blob4 from './blob-04.svg'
import blob5 from './blob-05.svg'
import blob6 from './blob-06.svg'
import blob7 from './blob-07.svg'
import blob8 from './blob-08.svg'
import blob9 from './blob-09.svg'
import blob10 from './blob-10.svg'

import blob11 from './blob-11.svg'
import blob12 from './blob-12.svg'
import blob13 from './blob-13.svg'
import blob14 from './blob-14.svg'
import blob15 from './blob-15.svg'
import blob16 from './blob-16.svg'
import blob17 from './blob-17.svg'
import blob18 from './blob-18.svg'
import blob19 from './blob-19.svg'
import blob20 from './blob-20.svg'

import blob21 from './blob-21.svg'
import blob22 from './blob-22.svg'
import blob23 from './blob-23.svg'
import blob24 from './blob-24.svg'
import blob25 from './blob-25.svg'
import blob26 from './blob-26.svg'
import blob27 from './blob-27.svg'
import blob28 from './blob-28.svg'
import blob29 from './blob-29.svg'
import blob30 from './blob-30.svg'

import blob31 from './blob-31.svg'
import blob32 from './blob-32.svg'
import blob33 from './blob-33.svg'
import blob34 from './blob-34.svg'
import blob35 from './blob-35.svg'
import blob36 from './blob-36.svg'
import blob37 from './blob-37.svg'
import blob38 from './blob-38.svg'
import blob39 from './blob-39.svg'
import blob40 from './blob-40.svg'

import blob41 from './blob-41.svg'
import blob42 from './blob-42.svg'
import blob43 from './blob-43.svg'
import blob44 from './blob-44.svg'
import blob45 from './blob-45.svg'
import blob46 from './blob-46.svg'
import blob47 from './blob-47.svg'
import blob48 from './blob-48.svg'
import blob49 from './blob-49.svg'
import blob50 from './blob-50.svg'

import blob51 from './blob-51.svg'
import blob52 from './blob-52.svg'
import blob53 from './blob-53.svg'
import blob54 from './blob-54.svg'
import blob55 from './blob-55.svg'

const Blobs = {
    blob1, blob2, blob3, blob5, blob4, blob6, blob7, blob8, blob9, blob10,
    blob11, blob12, blob13, blob14, blob15, blob16, blob17, blob18, blob19, blob20,
    blob21, blob22, blob23, blob24, blob25, blob26, blob27, blob28, blob29, blob30,
    blob31, blob32, blob33, blob34, blob35, blob36, blob37, blob38, blob39, blob40,
    blob41, blob42, blob43, blob44, blob45, blob46, blob47, blob48, blob49, blob50,
    blob51, blob52, blob53, blob54, blob55,
}

export default Blobs;